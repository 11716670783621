<template>
    <div class="col-md col-12">
        <div class="card card-white">
            <div class="card-body p-2 ">
                <label
                    class="mb-1 d-flex align-items-center cursor-pointer"
                    :for="speed.id"
                    v-for="speed in speeds"
                    :key="speed.id"
                >
                    <input type="radio" :name="speed.id" :id="speed.id" :value="speed.speed" v-model="chosenSpeed" />
                    <span class="ml-2">{{ speedName(speed.speed) }} ({{ speed.price }} mCoin)</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "instagram-speed",
    props: {
        speeds: Array,
        server: String
    },
    data() {
        return {
            chosenSpeed: null
        }
    },
    created() {
        this.findDefaultSpeed()
    },
    watch: {
        speeds() {
            this.findDefaultSpeed()
        },
        chosenSpeed(value) {
            if (this.server) {
                this.$emit("updateChosenSpeed", value, this.server)
            } else {
                this.$emit("updateChosenSpeed", value)
            }
        }
    },
    methods: {
        findDefaultSpeed() {
            if (this.speeds) {
                const defaultObj = this.speeds.find(obj => {
                    return obj.default == 1
                })
                if (defaultObj) {
                    this.chosenSpeed = defaultObj.speed
                }
            }
        },
        speedName(speed) {
            switch (speed) {
                case "low":
                    return "Chậm"
                case "normal":
                    return "Trung bình"
                case "medium":
                    return "Thông thường"
                case "high":
                    return "Nhanh"
                case "fast":
                    return "Cực nhanh"
                default:
                    return ""
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>

import { request } from "@/utils/request"

export function orderSeedingJob(data) {
    return request({
        url: "api/advertising/instagram/create",
        method: "post",
        data
    })
}
export function getListJobOrder(param = null) {
    return request({
        url: "api/advertising/instagram/list" + (param ? param : ""),
        method: "get"
    })
}

export function removeOrder(data) {
    return request({
        url: "api/advertising/instagram/remove",
        method: "post",
        data
    })
}
